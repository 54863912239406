<div class="flex flex-col gap-6 md:text-left md:flex-row md:gap-0">
	<div class="md:w-1/2 text-black/[.6] px-4 pt-2 md:py-4 md:pl-6 md:pr-4">
		<div *ngIf="isCardBasedLending" class="flex justify-between py-3 border-b border-black/[.06]">
			<p translate="application-summary.card-verified"></p>
			<div class="flex justify-end text-system-green">
				<p class="mr-1 font-bold text-right text-system-green" translate="application-summary.card-verified-enrolled"></p>
				<app-svg-icon [iconName]="'check-circle.svg'" [iconId]="'check-circle'"></app-svg-icon>
			</div>
		</div>
		<div class="flex justify-between py-3 border-b border-black/[.06]">
			<p translate="application-summary.application-approved"></p>
			<div class="flex justify-end text-system-green" *ngIf="isRequestApproved">
				<p class="mr-2 font-bold text-right text-system-green" translate="application-summary.credit-assessment-approved"></p>
				<app-svg-icon [iconName]="'check-circle.svg'" [iconId]="'check-circle'"></app-svg-icon>
			</div>
			<div class="flex justify-end" *ngIf="!isRequestApproved">
				<p class="mr-2 font-bold text-right text-system-red" translate="application-summary.credit-assessment-pending-approval"></p>
				<app-svg-icon [iconName]="'waiting.svg'" [iconId]="'waiting'"></app-svg-icon>
			</div>
		</div>
		<div class="flex justify-between py-3 border-b border-black/[.06]">
			<p translate="application-summary.contract-status"></p>
			<div class="flex justify-end text-system-green" *ngIf="contractsSigned">
				<p class="mr-1 font-bold text-right text-system-green" translate="application-summary.contract-signed"></p>
				<app-svg-icon [iconName]="'check-circle.svg'" [iconId]="'check-circle'"></app-svg-icon>
			</div>
			<div class="flex justify-end" *ngIf="!contractsSigned">
				<p class="mr-1 font-bold text-right text-system-red" translate="application-summary.contract-awaiting-signature"></p>
				<app-svg-icon [iconName]="'waiting.svg'" [iconId]="'waiting'"></app-svg-icon>
			</div>
		</div>

		<div class="flex justify-between py-3 border-b border-black/[.06]" *ngIf="hasPreviousLoan">
			<p translate="application-summary.new-loan"></p>
			<p class="font-bold text-right ">{{ (pendingRequest?.new_principal_due.amount - pendingRequest?.current_principal_due.amount) | asMoney:currency:-1  }}</p>
		</div>
		<div class="flex justify-between py-3 border-b border-black/[.06]" *ngIf="hasPreviousLoan">
			<p translate="application-summary.existing-loan"></p>
			<p class="font-bold text-right">{{ (pendingRequest?.current_principal_due.amount) | asMoney:currency:-1 }}</p>
		</div>
		<div class="flex justify-between py-3 border-b border-black/[.06]" *ngIf="!hasPreviousLoan">
			<p translate="application-summary.loan-amount"></p>
			<p class="font-bold text-right">{{ pendingRequest?.new_principal_due.amount | asMoney:currency:-1  }}</p>
		</div>
		<div class="flex justify-between py-3 border-b border-black/[.06]" *ngIf="hasPreviousLoan">
			<p translate="application-summary.new-total-loan-amount"></p>
			<p class="font-bold text-right">{{ pendingRequest?.new_principal_due.amount | asMoney:currency:-1  }}</p>
		</div>

		<div class="flex justify-between py-3 border-b border-black/[.06]">
			<p translate="application-summary.duration"></p>
			<p class="font-bold text-right">{{ pendingRequest?.duration_in_months }} {{'input.suffix.months' | translate}}</p>
		</div>
		<div class="flex justify-between py-3 border-b border-black/[.06]">
			<p translate="application-summary.cost"></p>
			<p class="font-bold text-right ">{{ pendingRequest?.new_interest_due.amount | asMoney:currency:0  }}</p>
		</div>

		<div class="flex justify-between py-3 border-b border-black/[.06]">
			<p translate="application-summary.interest-rate"></p>
			<p class="font-bold text-right ">
				{{ ((pendingRequest?.price_point.interestPercentage || 0) + '%') }}
			</p>
		</div>
	</div>
	<div class="md:w-1/2 text-black/[.6] px-5 pb-2 md:py-4 md:pl-6 md:pr-4 flex flex-col justify-between">
		<div>
			<div class="flex justify-between py-3 border-b border-black/[.06]">
				<p translate="application-summary.payment"></p>
				<p class="text-xl font-bold leading-none text-right text-highlighted">
					{{ (pendingRequest?.payment_schedule.standard_payment | money:0)  }}
					<span *ngIf="paysWeekly()" class="text-base" translate="application-summary.payment-interval.weekly"></span>
					<span *ngIf="paysDaily()" class="text-base" translate="application-summary.payment-interval.daily"></span>
					<span *ngIf="paysMonthly()" class="text-base" translate="application-summary.payment-interval.monthly"></span>
				</p>
			</div>
			<div class="flex justify-between py-3 border-b border-black/[.06]">
				<p translate="application-summary.total"></p>
				<p class="font-bold text-right ">
					{{ (pendingRequest?.new_principal_due.amount + pendingRequest?.new_interest_due.amount) | asMoney:currency:0  }}
				</p>
			</div>
		</div>
		<div>
			<p class="pt-6 pb-4 text-black/[.6] text-center">{{'application-summary.pre' | translate}}</p>
			<app-button [disabled]="!canCancelApplication" (action)="cancelApplication.emit()" type="small">
				{{ 'application-summary.cancel' | translate }}
			</app-button>
		</div>
	</div>
</div>
