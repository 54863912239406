<div class="flex justify-center w-full">
	<div [ngClass]="widthClass" class="flex flex-col flex-1 w-full px-6 py-6 sm:px-10 rounded-2xl bg-system-green">
		<div class="relative flex w-full gap-2">
			<div class="text-center sm:text-left">
				<p class="text-black/[.6]" translate="approved-payout.subtitle"></p>
				<h1 class="font-medium text-black" translate="approved-payout.title"></h1>
				<p class="pt-2 text-black/[.6]" translate="approved-payout.pre"></p>
			</div>
		</div>
	</div>
</div>
